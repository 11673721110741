import { Box, Card, CardContent } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DataGrid, GridPaginationModel } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { ItemListRequest } from "../../../models/Items/ItemListRequest";
import { SortDirection } from "../../../models/Enum";
import api from "../../../services/ApiService";
import { ApiResponse } from "../../../models/ApiResponse";
import { ProductListResponse } from "../ProductList";
import { ItemBasicDetail } from "../../../models/Items/ItemBasicDetail";
import imageNotAvailable from "../../../no-image.png";
import { EditViewGridItemData } from "../../../models/Items/EditViewGridItemData";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import { useAppSelector } from "../../../store";
import { THUMBNAIL_IMAGE_API_URL } from "./Constants";

interface EditProductGridViewProps {
  editItemId: string;
};

const EditProductGridView = (props: EditProductGridViewProps) => {
  const { editItemId } = props;
  
  const [isLoading, setIsLoading] = useState(true);
  const [editModeGridData, setEditModeGridData] = useState<EditViewGridItemData[]>([]);
  const [productsTotalCount, setProductsTotalCount] = useState(0);

  const navigate = useNavigate();
  
  const queryParameters = new URLSearchParams(window.location.search);
  const page = queryParameters.get("page");

  const filterValuesSelected = useAppSelector((state) => state.filters.productListView.selectedFilterValues);
  const itemListRequestState = useAppSelector((state) => state.items.itemListRequest);

  const initialItemListRequest = {
    pageIndex: itemListRequestState.pageIndex || 1,
    pageSize: itemListRequestState.pageSize || 10,
    sortBy: "id",
    sortDirection: SortDirection.DESC,
    ...filterValuesSelected
  };

  const [itemListRequest, setItemListRequest] = useState<ItemListRequest>(
    initialItemListRequest
  );

  // This is used to display columns in Edit Product - Grid View
  const editProductListColumns = [
    {
      field: "image",
      headerName: "",
      renderCell: (params) => (
        <div className="edit-grid-image-cell">
          <img src={params.value} alt="Image" />
        </div>
      ),
      width: 50,
      height: 50,
      cellClassName: "sidebarImageListing",
    },
    {
      field: "stockcode",
      width: 160,
      headerName: "",
      renderCell: (params) => (
        <div className="edit-grid-product-detail-cell">
          <div onClick={() => navigate(`/edit-product/${params.id}?page=${itemListRequest.pageIndex}`)}>
            {params.value || `Product Id ${params.id}`}
          </div>
          <div className="product-short-description">
            {params.row?.shortDesc}
          </div>
        </div>
      )
    },
  ];

  useEffect(() => {
    const getItems = async () => {
      try {
        setIsLoading(true);
        api
          .post<ApiResponse<ProductListResponse>>("/item/list", itemListRequest)
          .then((response) => {
            if (response.isSuccess) {
              setProductsTotalCount(response?.data?.totalCount);
              const items:ItemBasicDetail[] = (response?.data?.items || []);
              const editItemsGridRows = (items || []).map((itemsList) => {
                return {
                  id: itemsList.id,
                  stockcode: itemsList.stockcode,
                  shortDesc: itemsList.shortDesc,
                  image: itemsList.imageFileName ? `${THUMBNAIL_IMAGE_API_URL}?fileName=${encodeURIComponent(itemsList.imageFileName)}` : imageNotAvailable
                };
              });
              setEditModeGridData(editItemsGridRows);
              setIsLoading(false);
            } else {
              throw new Error(response.message);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.error("Exception from product list", error);
          });
      } catch (error: any) {
        setIsLoading(false);
        console.error("Exception from product list", error);
      }
    };
    getItems();
  }, [itemListRequest]);

  const onPaginationChange = (pagination: GridPaginationModel) => {
    setItemListRequest({
      ...itemListRequest,
      pageIndex: pagination.page + 1,
      pageSize: pagination.pageSize,
    });
    navigate(`/edit-product/${editItemId}?page=${pagination.page + 1}`);
  };

  return (
    <>
    <Loader isLoading={isLoading} />
    <Box
      sx={{
        height: "70%",
        width: "24%",
        overflow: "hidden",
        transition: "width 0.5s",
        marginLeft: "15px",
      }}
    >
      <Card sx={{ marginBottom: 1 }} className="productView-accordian-main">
        <CardContent sx={{ padding: 0 }} className="productView-accordian-rightList">
          <p
            onClick={() => navigate("/products")}
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "10px",
              marginTop: "0px",
              cursor: "pointer",
              marginBottom: "1px",
              padding: "20px 12px",
              background: "#fff",
            }}
          >
            <ArrowBackIcon />
            Back To List
          </p>
          <DataGrid
            rows={editModeGridData}
            columns={editProductListColumns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            disableRowSelectionOnClick={true}
            className="hide-header"
            paginationMode="server"
            onPaginationModelChange={onPaginationChange}
            paginationModel={{
              page: Number(page) - 1, 
              pageSize: itemListRequest.pageSize,
            }}
            rowCount={productsTotalCount}
            getRowClassName={(params) =>
              String(params.row.id) === editItemId ? 'Mui-active' : 'Mui-inactive'
            }
            sx={{ height: "550px" }}
          />
        </CardContent>
      </Card>
    </Box>
    </>
  )
}

export default EditProductGridView;