import { Box, Card, CardContent, Button } from "@mui/material";
import React, { useState, ChangeEvent, useRef } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import Papa from "papaparse";
import { ApiResponse } from "../../models/ApiResponse";
import api from "../../services/ApiService";
import InfoIcon from '@mui/icons-material/Info';

const BulkUploadAttachments = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileData, setFileData] = useState<any[]>([]);
  const [columnData, setColumnData] = useState<any[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleFileChange = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    setSelectedFile(file);
  };

  const handleClear = () => {
    setColumnData([]);
    setFileData([]);
    setIsError(false);
    setSelectedFile(null);
    fileInputRef.current.value = "";
  };
  const fileInputRef = useRef(null);

  const handleBrowseClick = () => {
    setColumnData([]);
    setFileData([]);
    setIsError(false);
    fileInputRef.current.click();
  };

  const processErrorData = (errorResponse) => {
    Papa.parse(selectedFile, {
      complete: (result) => {
        const rowData = result?.data; // Exclude header row
        const errorRowData = rowData?.filter((item: any, index: number) =>
          errorResponse?.errors?.includes(String(item?.Link))
        );

        if (errorRowData?.length > 0) {
          const columns = Object.keys(errorRowData[0]).map((field) => ({
            field,
            headerName: field,
            width: field == "Link" ? 450 : 200,
          }));

          const convertedData = errorRowData.map((item, index) => {
            const updatedItem = {
              ...item,
              id: `${item?.PrjCode}-${index + 1}`, // Create a unique id by combining PrjCode and index
            };
            return updatedItem;
          });

          setColumnData(columns);
          setFileData(convertedData);
        }

        fileInputRef.current.value = ""; // Reset file input
        setErrorMessage(errorResponse.message || "An error occurred");
        setSelectedFile(null);
        setIsError(true);
      },
      header: true,
    });
  };

  const handleSubmit = async () => {
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
    const formData = new FormData();
    if (selectedFile.size < maxSizeInBytes) {
      formData.append("file", selectedFile); // Assuming 'file' is the key expected by the server
      api
        .post<ApiResponse<any>>("/Attachment/bulk-upload-csv", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.isSuccess) {
            toast.success(response.message);
            setSelectedFile(null);
            fileInputRef.current.value = "";
          }
        })
        .catch((error) => {
          if (error?.response?.data?.errors?.length > 0) {
            const errorResponse = error.response.data;
            processErrorData(errorResponse);
          }
          console.error("Exception from bulk upsert quote file", error);
        });
    } else {
      toast.error("Upload file less than 5MB");
    }
  };
  const handleUpdateSampleDownload = () => {
    const demoCsvUrl = "./sampleBulkUploadAttachments.csv";
    const link = document.createElement("a");
    link.href = demoCsvUrl;
    link.setAttribute("download", "sampleBulkUploadAttachments.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <Card sx={{ marginBottom: 1 }}>
        <CardContent>
          <div
            style={{
              color: "#454545",
              padding: "20px",
              borderBottom: "1px solid #ccc",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              textTransform: "capitalize",
            }}
          >
            <p style={{ margin: "0px" }}>Bulk Upload Attachments </p>
            <div style={{ display: "flex", alignItems: "start", marginTop: "10px" }}>
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <InfoIcon sx={{ fontSize: 18, marginRight: "2px" }} />
                  <p style={{ fontSize: "12px", margin: "0" }}>Note:</p>
                </div>
                <ul style={{ fontSize: "12px", marginTop: "4px", paddingLeft: "20px" }}>
                  <li>Attachments must be uploaded to the SharePoint '_Intake' Folder before submitting the Bulk Upload Attachments CSV file</li>
                </ul>
              </div>
            </div>
          </div>
          <Box sx={{ height: 1, backgroundColor: "#ccc" }} />
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "flex-start",
              padding: "20px",
            }}
          >
            <input
              type="file"
              id="fileInput"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept=".csv"
            />
            <div
              className="inputWrap"
              style={{ width: "40%", marginRight: "10px", maxWidth: "100%" }}
            >
              <input
                type="text"
                name="fileName"
                id="fileName"
                value={selectedFile ? selectedFile.name : ""}
                style={{
                  width: "100%",
                  height: "35px",
                }}
                readOnly
              />

              <p
                style={{
                  color: "#717171",
                  fontSize: "15px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                  letterSpacing: "0.17px",
                }}
              >
                CSV (max. 5MB).
                <span
                  onClick={handleUpdateSampleDownload}
                  style={{ cursor: "pointer", paddingLeft: "10px" }}
                >
                  <strong>Download sample CSV for bulk upload attachments</strong>
                </span>
              </p>
            </div>

            <Button
              onClick={handleBrowseClick}
              variant="contained"
              style={{
                borderRadius: "0px",
                border: "0.5px solid grey",
                width: "150px",
                height: "35px",
                marginRight: "10px",
              }}
            >
              Browse
            </Button>

            <Button
              onClick={handleSubmit}
              style={{
                borderRadius: "0px",
                border: "0.5px solid grey",
                width: "150px",
                height: "35px",
                marginRight: "10px",
              }}
              disabled={selectedFile ? false : true}
            >
              Submit
            </Button>
            <Button
              onClick={handleClear}
              style={{
                borderRadius: "0px",
                border: "0.5px solid grey",
                width: "150px",
                height: "35px",
              }}
            >
              Clear
            </Button>
          </Box>

          {isError ? (
            <>
              <div
                style={{
                  color: "red",
                  padding: "20px",
                  borderBottom: "1px solid #ccc",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                  textTransform: "capitalize",
                }}
              >
                <p style={{ margin: "0px" }}>{errorMessage}</p>
              </div>
              <Box className="bulk-update-grid-cell" sx={{ height: "100%", width: "100%" }}>
                {fileData.length > 0 && (
                  <DataGrid
                    rows={fileData}
                    columns={columnData}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableRowSelectionOnClick={true}
                    getRowHeight={() => 'auto'}
                  />
                )}
              </Box>
            </>
          ) : (
            ""
          )}
        </CardContent>
      </Card>
    </>
  );
};
export default BulkUploadAttachments;
