import { useEffect, useState } from "react";
import { SettingGrid } from "../../../components/ManageSettingComponents/SettingGrid";
import { SkuType } from "../../../models/SkuType";
import SessionService from "../../../services/SessionService";
import { Permissions } from "../../../models/Enum";
import api from "../../../services/ApiService";
import { ApiResponse } from "../../../models/ApiResponse";
import { GridColDef } from "@mui/x-data-grid";
import { Button, Switch } from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../../utils/constants";
import { GRID_ACTION_DELETE_ICON, GRID_ACTION_EDIT_ICON } from "../../../components/GridUtilityComponents";
import DeleteValidationModal from "../../../components/ManageSettingComponents/DeleteValidationModal";
import SkuTypeModal from "./SkuTypeModal";
import { useFormik } from "formik";
import { SessionDetails } from "../../../models/SessionDetails";
import * as Yup from "yup";


export default function SkuTypeSetting (){
    const [skutypeList, setSkuTypeList] = useState<SkuType[]>([]);
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false); 

    const manageAppSetting = SessionService.getInstance().hasPermission(Permissions.ManageAppSettings);

    const SkuTypeGridCollumns: GridColDef[] = [
        {field: "id", headerName: "Id", width: 100},
        {field: "name", headerName: "Sku Name", width: 250 },
        {field: "code", headerName: "Code", width: 150},
        {field: "skuSelect", headerName: "Sku Select", width: 150},
        {
            field: "isActive",
            headerName: "Is Active",
            width: 200, 
            sortable: false,
            renderCell: (params: any) => (
                <>
                    <Switch
                        checked = {params.value}
                        onChange = {async (e) => {
                            await updateSkuTypeActive(params.row, e.target.checked);
                        }}
                        color={"primary"}
                        inputProps={{ "aria-label": "controlled" }}
                        disabled = {!manageAppSetting}
                    />
                </>
            )
        },
        // {
        //     field: "updatedDate", 
        //     headerName: "Updated Date", 
        //     width: 200,
        //     valueFormatter: (params) => moment(params?.value).format(DATE_TIME_FORMAT),
        // },
        {
            field: "actions", 
            type: "actions",
            headerName: "Actions", 
            width: 200,
            getActions: (params: any) => [
                GRID_ACTION_EDIT_ICON(params, handleEditClickOpen),
                GRID_ACTION_DELETE_ICON(params, handleDeleteModalOpen)
            ],
        },
    ];

    const fetchData = async () => {
        const getSkuTypeResponse = await api.get<ApiResponse<SkuType[]>>("/PickList/sku-types");
        setSkuTypeList(getSkuTypeResponse?.data || []);
    }

    useEffect(() => {
        fetchData();
    }, [])

    const updateSkuTypeActive = async (row: SkuType, isActive: boolean) => {
        row.isActive = isActive;
        try{
            api
                .put<ApiResponse<SkuType>>("/PickList/sku-type", row)
                .then(async (response: any) => {
                    if(response.isSuccess) {
                        toast.success(response.message);
                        await fetchData();
                    } else {
                        throw new Error(response.message);
                    }
                })
                .catch((err: any) => {} )
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const handleCloseModal = () => {
        setDeleteModalOpen(false);
        setShowAddUpdateModal(false);
        formik.resetForm();
    };

    const handleAddClickOpen = () => {
        setDisableSubmit(false);
        formik.resetForm();
        setShowAddUpdateModal(true);
    }

    const handleEditClickOpen = (gridParams: any) => {
        setDisableSubmit(false);
        formik.resetForm();
        setShowAddUpdateModal(true);
        formik.setValues({ ...gridParams.row });
    };

    const handleDeleteModalOpen = (gridParams: any) => {
        setDeleteModalOpen(true);
        formik.setValues({ ...gridParams.row });
    };

    const deleteSubmit = async () => {
        try {
            api
              .delete<ApiResponse<SessionDetails>>(
                `/Picklist/sku-type/${formik.values.id}`
              )
              .then(async (response: any) => {
                if (response.isSuccess) {
                  setDeleteModalOpen(false);
                  formik.resetForm();
                  toast.success(response.message);
                  await fetchData();
                } else {
                  throw new Error(response.message);
                }
              })
              .catch(() => {});
          } catch (err: any) {
            toast.error(err.message);
          }
    };

    const formik = useFormik({
        initialValues: {
            id: 0,
            name: "",
            code: "",
            skuSelect: "No",
            isActive: true,
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .trim()
                .required("Sku Type Name is required")
                .min(1, "Sku Type Name must be at least 1 characters")
                .max(40, "Sku Type Name must be at most 40 characters")
                .test(
                    "check-name-duplicate",
                    "Sku Type Name already exists",
                    (value) => {
                      return !skutypeList.some(
                        (item) =>
                          item.name.trim().toLowerCase() === value.trim().toLowerCase() &&
                          item.id !== formik.values.id
                      );
                    }
                  ),
            code: Yup.string()
                .trim()
                .required("Sku Type Code is required")
                .min(1, "Sku Type Code must be at least 1 characters")
                .max(15, "Sku Type Code must be at most 40 characters")
                .test(
                    "check-code-duplicate",
                    "Sku Type Code already exists",
                    (value) => {
                    return !skutypeList.some(
                        (item) =>
                        item.code.trim().toLowerCase() === value.trim().toLowerCase() &&
                        item.id !== formik.values.id
                    );
                    }
                ),
            skuSelect: Yup.string()
                .required("Sku Select is required")
        }),
        onSubmit: async (values) => {
            setDisableSubmit(true);
            if (values.id > 0) {
                api
                  .put<ApiResponse<boolean>>("/PickList/sku-type", {
                    id: values.id,
                    name: values.name.trim(),
                    code: values.code.trim(),
                    skuSelect: values.skuSelect,
                    isActive: JSON.parse(`${values.isActive}`),
                  })
                  .then(async (response: any) => {
                    if (response.isSuccess) {
                      setShowAddUpdateModal(false);
                      formik.resetForm();
                      toast.success(response.message);
                      await fetchData();
                    } else {
                      throw new Error(response.message);
                    }
                  })
                  .catch(() => {});
              } else {
                api
                  .post<ApiResponse<SessionDetails>>("/PickList/sku-type", {
                    name: values.name,
                    code: values.code,
                    skuSelect: values.skuSelect,
                    isActive: values.isActive,
                  })
                  .then(async (response: any) => {
                    if (response.isSuccess) {
                      setShowAddUpdateModal(false);
                      formik.resetForm();
                      toast.success(response.message);
                      await fetchData();
                    } else {
                      throw new Error(response.message);
                    }
                  })
                  .catch(() => {});
              }
            }
        })

    return (
        <>
            <div>
            { manageAppSetting && <Button variant="contained" onClick={handleAddClickOpen}>
          Add Sku Type
        </Button>}
                <SkuTypeModal 
                    isOpen={showAddUpdateModal}
                    onClose={handleCloseModal} 
                    formik={formik}  
                    isButtonDisabled={disableSubmit}              
                />
                <DeleteValidationModal
                    addModalOpen={deleteModalOpen}
                    handleCloseModal={handleCloseModal}
                    deleteSubmit={deleteSubmit}
                    fieldIdentifier={"Sku Type"}
                />
            </div>
            <SettingGrid
                data = {skutypeList}
                column = {SkuTypeGridCollumns}
                sortByField="id"
                sortByOrder="desc"
                columnVisibility={{
                  actions: manageAppSetting,
                }}
            />
        </>
    )
};