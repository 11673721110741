import { useEffect, useState } from "react";
import { Supplier } from "../../../models/Supplier";
import { ApiResponse } from "../../../models/ApiResponse";
import api from "../../../services/ApiService";
import { SettingGrid } from "../../../components/ManageSettingComponents/SettingGrid";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import moment from "moment";
import { Button, Switch } from "@mui/material";
import { toast } from "react-toastify";
import SupplierModal from "./SupplierModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SessionDetails } from "../../../models/SessionDetails";
import DeleteValidationModal from "../../../components/ManageSettingComponents/DeleteValidationModal";
import { DATE_TIME_FORMAT } from "../../../utils/constants";
import { GRID_ACTION_DELETE_ICON, GRID_ACTION_EDIT_ICON } from "../../../components/GridUtilityComponents";
import { Permissions } from "../../../models/Enum";
import SessionService from "../../../services/SessionService";

export default function SupplierSetting() {
  const [supplierList, setSupplierList] = useState<Supplier[]>([]);
  const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const manageAppSetting = SessionService.getInstance().hasPermission(Permissions.ManageAppSettings);

  const SupplierGridCollumns: GridColDef[] = [
    { field: "id", headerName: "Id", width: 100 },
    { field: "name", headerName: "Supplier Name", width: 300 },
    { field: "sapCode", headerName: "SAP Code", width: 150 },
    { field: "jdeCode", headerName: "JDE Code", width: 150 },
    {
      field: "isActive",
      headerName: "Is Active",
      width: 200,
      sortable: false,
      renderCell: (params: any) => (
        <>
          <Switch
            checked={params.value}
            onChange={async (e) => {
              await updateSupplierActive(params.row, e.target.checked);
            }}
            color={"primary"}
            inputProps={{ "aria-label": "controlled" }}
            disabled = {!manageAppSetting}
          />
        </>
      ),
    },
    // {
    //   field: "updatedDate",
    //   headerName: "Updated Date",
    //   width: 200,
    //   valueFormatter: (params) => moment(params?.value).format(DATE_TIME_FORMAT),
    // },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      getActions: (params: any) => [
        GRID_ACTION_EDIT_ICON(params, handleEditClickOpen),
        GRID_ACTION_DELETE_ICON(params, handleDeleteModalOpen)
      ],
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const getSupplierResponse = await api.get<ApiResponse<Supplier[]>>(
      "/Picklist/suppliers"
    );
    setSupplierList(getSupplierResponse?.data || []);
  };

  const handleEditClickOpen = (gridParams: any) => {
    formik.resetForm();
    setShowAddUpdateModal(true);
    setDisableSubmit(false);
    formik.setValues({ ...gridParams.row });
  };

  const handleAddClickOpen = () => {
    formik.resetForm();
    setShowAddUpdateModal(true);
    setDisableSubmit(false);
  };

  const handleDeleteModalOpen = (gridParams: any) => {
    setDeleteModalOpen(true);
    formik.setValues({ ...gridParams.row });
  };

  const handleCloseModal = () => {
    setShowAddUpdateModal(false);
    setDeleteModalOpen(false);
    formik.resetForm();
  };

  const updateSupplierActive = (row: Supplier, isActive: boolean) => {
    row.isActive = isActive;
    try {
      api
        .put<ApiResponse<number>>("/Picklist/supplier", row)
        .then(async (response: any) => {
          if (response.isSuccess) {
            toast.success(response.message);
            await fetchData();
          } else {
            throw new Error(response.message);
          }
        })
        .catch(() => {});
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  const deleteSubmit = () => {
    try {
      api
        .delete<ApiResponse<SessionDetails>>(
          `/Picklist/supplier/${formik.values.id}`
        )
        .then(async (response: any) => {
          if (response.isSuccess) {
            setDeleteModalOpen(false);
            formik.resetForm();
            toast.success(response.message);
            await fetchData();
          } else {
            throw new Error(response.message);
          }
        })
        .catch(() => {});
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      id: 0,
      name: "",
      sapCode: null,
      jdeCode: null,
      isActive: true,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .min(1, "Supplier Name must be at least 1 characters")
        .max(40, "Supplier Name must be at most 40 characters")
        .required("Supplier Name is required")
        .test(
          "check-name-duplicate",
          "Supplier Name already exists",
          (value) => {
            return !supplierList.some(
              (item) =>
                item.name.trim().toLowerCase() === value.trim().toLowerCase() &&
                item.id !== formik.values.id
            );
          }
        ),
        sapCode: Yup.string()
        // .transform((value) => value.trim().toUpperCase())
        // .min(1, "SAP Code must be at least 1 characters")
        .nullable()
        .max(3, "SAP Code must be at most 3 characters"),
        // .required("SAP Code is required")
        // .matches(/^\d+$|N\/A$/, "SAP Code Must be a Number")
        // .test(
        //   "check-sap-code-duplicate",
        //   "SAP Code already exists",
        //   (value) => {
        //     return !supplierList.some(
        //       (item) =>
        //         item.sapCode.trim().toLowerCase() === value.trim().toLowerCase() &&
        //         item.id !== formik.values.id && item.sapCode!== 'N/A'
        //     );
        //   }
        // ),
        jdeCode: Yup.number()
        .nullable()
    }),
    onSubmit: async (values) => {
      console.log(values)
      setDisableSubmit(true);
      if (values.id > 0) {
        api
          .put<ApiResponse<number>>("/Picklist/supplier", {
            id: values.id,
            name: values.name.trim(),
            sapCode: values.sapCode || null,
            jdeCode: values.jdeCode || null,
            isActive: JSON.parse(`${values.isActive}`),
          })
          .then(async (response: any) => {
            if (response.isSuccess) {
              setShowAddUpdateModal(false);
              formik.resetForm();
              toast.success(response.message);
              await fetchData();
            } else {
              throw new Error(response.message);
            }
          })
          .catch(() => {});
      } else {
        api
          .post<ApiResponse<number>>("/Picklist/supplier", {
            name: values.name,
            sapCode: values.sapCode || null,
            jdeCode: values.jdeCode || null,
            isActive: JSON.parse(`${values.isActive}`),
          })
          .then(async (response: any) => {
            if (response.isSuccess) {
              setShowAddUpdateModal(false);
              formik.resetForm();
              toast.success(response.message);
              await fetchData();
            } else {
              throw new Error(response.message);
            }
          })
          .catch(() => {});
      }
    },
  });

  return (
    <>
      <div>
        {manageAppSetting && <Button variant="contained" onClick={handleAddClickOpen}>
          Add Supplier
        </Button>}
        <SupplierModal
          isOpen={showAddUpdateModal}
          onClose={handleCloseModal}
          formik={formik}
          isButtonDisabled={disableSubmit}
        />
        <DeleteValidationModal
          addModalOpen={deleteModalOpen}
          handleCloseModal={handleCloseModal}
          deleteSubmit={deleteSubmit}
          fieldIdentifier={"Supplier Name"}
        />
      </div>
      <SettingGrid
        data={supplierList}
        column={SupplierGridCollumns}
        sortByField="id"
        sortByOrder="desc"
        columnVisibility={{
          actions: manageAppSetting,
        }}
      />
    </>
  );
}
