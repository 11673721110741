import { useEffect, useState } from "react";
import { ManufacturingMethod } from "../../../models/ManufacturingMethod";
import api from "../../../services/ApiService";
import { ApiResponse } from "../../../models/ApiResponse";
import { SettingGrid } from "../../../components/ManageSettingComponents/SettingGrid";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import moment from "moment";
import { Button, Switch } from "@mui/material";
import { toast } from "react-toastify";
import { SessionDetails } from "../../../models/SessionDetails";
import ManufacturingModal from "./ManufacturingModal";
import DeleteValidationModal from "../../../components/ManageSettingComponents/DeleteValidationModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DATE_TIME_FORMAT } from "../../../utils/constants";
import { GRID_ACTION_DELETE_ICON, GRID_ACTION_EDIT_ICON } from "../../../components/GridUtilityComponents";
import SessionService from "../../../services/SessionService";
import { Permissions } from "../../../models/Enum";

export default function ManufactoryMethodSetting() {
  const [manufacturingMethodList, setManufacturingMethodList] = useState<
    ManufacturingMethod[]
  >([]);

  const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const manageAppSetting = SessionService.getInstance().hasPermission(Permissions.ManageAppSettings);

  const ManufacturingMethodGridCollumns: GridColDef[] = [
    { field: "id", headerName: "Id", width: 70 },
    { field: "method", headerName: "Manufacturing Method", width: 250 },
    {
      field: "isActive",
      headerName: "Is Active",
      width: 200,
      sortable: false,
      renderCell: (params: any) => (
        <>
          <Switch
            checked={params.value}
            onChange={async (e) => {
              await updateManufacturingMethodActive(
                params.row,
                e.target.checked
              );
            }}
            color="primary"
            inputProps={{ "aria-label": "controlled" }}
            disabled = {!manageAppSetting}
          />
        </>
      ),
    },
    // {
    //   field: "updatedDate",
    //   headerName: "Updated Date",
    //   width: 200,
    //   valueFormatter: (params) => moment(params?.value).format(DATE_TIME_FORMAT),
    // },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 200,
      getActions: (params: any) => [
        GRID_ACTION_EDIT_ICON(params, handleEditClickOpen),
        GRID_ACTION_DELETE_ICON(params, handleDeleteModalOpen)
      ],
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const getManufacturingMethodResponse = await api.get<
      ApiResponse<ManufacturingMethod[]>
    >("/Picklist/manufacturing-methods");
    setManufacturingMethodList(getManufacturingMethodResponse?.data || []);
  };

  const handleEditClickOpen = (gridParams: any) => {
    formik.resetForm();
    setShowAddUpdateModal(true);
    setDisableSubmit(false);
    formik.setValues({ ...gridParams.row });
  };

  const handleAddClickOpen = () => {
    formik.resetForm();
    setShowAddUpdateModal(true);
    setDisableSubmit(false);
  };

  const handleDeleteModalOpen = (gridParams: any) => {
    setDeleteModalOpen(true);
    formik.setValues({ ...gridParams.row });
  };

  const handleCloseModal = () => {
    setShowAddUpdateModal(false);
    setDeleteModalOpen(false);
    formik.resetForm();
  };

  const updateManufacturingMethodActive = async (
    row: ManufacturingMethod,
    isAcitve: boolean
  ) => {
    row.isActive = isAcitve;
    try {
      api
        .put<ApiResponse<number>>("/Picklist/manufacturing-method", row)
        .then(async (response: any) => {
          if (response.isSuccess) {
            toast.success(response.message);
            await fetchData();
          } else {
            throw new Error(response.message);
          }
        })
        .catch(() => {});
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  const deleteSubmit = () => {
    try {
      api
        .delete<ApiResponse<SessionDetails>>(
          `/Picklist/manufacturing-method/${formik.values.id}`
        )
        .then(async (response: any) => {
          if (response.isSuccess) {
            setDeleteModalOpen(false);
            formik.resetForm();
            toast.success(response.message);
            await fetchData();
          } else {
            throw new Error(response.message);
          }
        })
        .catch(() => {});
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      id: 0,
      method: "",
      isActive: true,
    },
    validationSchema: Yup.object({
      method: Yup.string()
        .trim()
        .required("Method is required")
        .min(1, "Method must be at least 1 characters")
        .max(40, "Method must be at most 40 characters")
        .test("check-duplicate", "Method already exists", (value) => {
          return !manufacturingMethodList.some(
            (item) =>
              item.method.trim().toLowerCase() === value.trim().toLowerCase() &&
              item.id !== formik.values.id
          );
        }),
    }),
    onSubmit: async (formData) => {
      setDisableSubmit(true);
      if (formData.id > 0) {
        api
          .put<ApiResponse<number>>("/Picklist/manufacturing-method", {
            id: formData.id,
            method: formData.method.trim(),
            isActive: JSON.parse(`${formData.isActive}`),
          })
          .then(async (response: any) => {
            if (response.isSuccess) {
              setShowAddUpdateModal(false);
              formik.resetForm();
              toast.success(response.message);
              await fetchData();
            } else {
              throw new Error(response.message);
            }
          })
          .catch(() => {});
      } else {
        api
          .post<ApiResponse<number>>("/Picklist/manufacturing-method", {
            method: formData.method,
            isActive: JSON.parse(`${formData.isActive}`),
          })
          .then(async (response: any) => {
            if (response.isSuccess) {
              setShowAddUpdateModal(false);
              formik.resetForm();
              toast.success(response.message);
              await fetchData();
            } else {
              throw new Error(response.message);
            }
          })
          .catch(() => {});
      }
    },
  });

  return (
    <>
      <div>
        {manageAppSetting && <Button variant="contained" onClick={handleAddClickOpen}>
          Add Method
        </Button>}

        <ManufacturingModal
          isOpen={showAddUpdateModal}
          onClose={handleCloseModal}
          formik={formik}
          isButtonDisabled={disableSubmit}
        />

        <DeleteValidationModal
          addModalOpen={deleteModalOpen}
          handleCloseModal={handleCloseModal}
          deleteSubmit={deleteSubmit}
          fieldIdentifier={"Manufacturing Method"}
        />
      </div>

      <SettingGrid
        data={manufacturingMethodList}
        column={ManufacturingMethodGridCollumns}
        sortByField="id"
        sortByOrder="desc"
        columnVisibility={{
          actions: manageAppSetting,
        }}
      />
    </>
  );
}
