import { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, FormControl, FormControlLabel, Grid, IconButton, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { FIELD_NAMES, PRICING_LAYOUT_CATEGORY, QUOTE_CALCULATION_FIELDS, TOOLING_LAYOUT_CATEGORY, QUOTE_APPROVAL_FIELDS, QUOTE_FIELD_SYMBOLS } from "./EditItem/Constants";
import { ProductLayoutFieldDetail } from "../../models/Items/ProductLayoutFieldDetail";
import ToggleSwitchButton from "../../components/ToggleSwitchButton";
import moment from "moment";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from '@mui/icons-material/Remove';
import { DATE_FORMAT } from "../../utils/constants";
import { ApiResponse } from "../../models/ApiResponse";
import api from "../../services/ApiService";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import { QuoteBasicDetail } from "../../models/Quotes/QuoteBasicDetail";
import * as Yup from "yup";
import { QuoteHistory } from "../../models/Quotes/QuoteHistory";
import { toast } from "react-toastify";
import DeleteValidationModal from "../../components/ManageSettingComponents/DeleteValidationModal";
import ThreeStateToggle from "../../components/ThreeStateToggleButton";
import ConfirmationModal from "../../components/ConfirmationModal";
import { DataGrid } from "@mui/x-data-grid";
import { Permissions } from "../../models/Enum";
import SessionService from "../../services/SessionService";

export type CreateUpdateQuoteRequest = {
  fobCost: number | null;
  targetFOBCost: number | null;
  tariffPercent: number | null;
  toolingCost: number | null;
  toolingNumber: string | null;
  toolingOpened: boolean;
  umapPrice: number | null;
  carNumber: string | null;
  cubeMultiplier: number | null;
  freightCost: number | null;
  quoteNumber: string | null;
  processedDate: number | null;
  quoteDate: number | null;
  commissionDollars: number | null;
  commissionPercent: number | null;
  dnToUMAPMultiplier: number | null;
  dutyCost?: number | null;
  grossGMDollars: number | null;
  grossGMPercent: number | null;
  landedCost: number | null;
  netMargin: number | null;
  netMarginDollars: number | null;
  percentOfFOB?: number | null;
  royaltyDollars?: number | null;
  royaltyPercent?: number | null;
  tariffDollars?: number | null;
  ipCube?: number | null;
  dnPrice?: number | null;
  updatedDate?: Date | null;
  productId?: number | null;
  id?: number | null;
};

const CreateUpdateQuote = () => {

  const [accordianExpanded, setAccordianExpanded] = useState<string[]>([PRICING_LAYOUT_CATEGORY.categoryName, TOOLING_LAYOUT_CATEGORY.categoryName]);
  const [quoteDetails, setQuoteDetails] = useState<QuoteBasicDetail>(null);
  const [quoteHistoryGridData, setQuoteHistoryGridData] = useState<QuoteHistory[]>();
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

  const [deleteModalOpen, setDeleteOpenModal] = useState(false);
  const [quoteHistoryId, setQuoteHistoryId] = useState(0);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [fieldToApprove, setFieldToApprove] = useState(null);
  const [approvalChecked, setApprovalChecked] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const location = useLocation();
  const productIdState = location.state?.productId;

  const { shortDesc, stockCode } = location.state;

  const sessionService = SessionService.getInstance();

  const initialValues: QuoteBasicDetail = {
    fobCost: null,
    targetFOBCost: null,
    tariffPercent: null,
    toolingCost: null,
    toolingNumber: "",
    toolingOpened: false,
    umapPrice: null,
    carNumber: "",
    quoteNumber: "",
    processedDate: null,
    quoteDate: null,
    cubeMultiplier: 0,
    freightCost: 0,
    commissionDollars: 0,
    commissionPercent: 0,
    dnToUMAPMultiplier: 0,
    grossGMDollars: 0,
    grossGMPercent: 0,
    landedCost: 0,
    netMargin: 0,
    netMarginDollars: 0,
    dutyCost: 0,
    dnPrice: 0,
    ipCube: 0,
    percentOfFOB: 0,
    royaltyDollars: 0,
    royaltyPercent: 0,
    tariffDollars: 0,
    costApproved: null,
    dnApproved: null,
    id: 0,
    productId: null
  };

  const customValidationForInputField = (fieldLabel: string) => {
    return Yup.string().trim().nullable().required(`${fieldLabel} is required`)
      .test('not-zero', `${fieldLabel} cannot be zero`, value => {
        return value != "0";
      })
  };

  const validations = Yup.object().shape({
    fobCost: customValidationForInputField("FOB Cost"),
    targetFOBCost: customValidationForInputField("Target FOB Cost"),
    tariffPercent: customValidationForInputField("Tariff Percent"),
    toolingCost: customValidationForInputField("Tooling Cost"),
    umapPrice: customValidationForInputField("UMAP Price"),
    quoteNumber: customValidationForInputField("Quote Number"),
    quoteDate: customValidationForInputField("Quote Date"),
    processedDate: customValidationForInputField("Processed Date"),
    carNumber: customValidationForInputField("CAR Number"),
    toolingNumber: customValidationForInputField("Tooling Number"),
  });

  const fetchQuoteDetails = async (quoteId: number) => {
    try {
      const resp = await api.get<ApiResponse<QuoteBasicDetail>>(`/Quote/${quoteId}`);
      if (resp?.isSuccess) {
        const { data } = resp;
        formik.setValues({ ...data });
        setQuoteDetails(data);
        setQuoteHistoryGridData(data?.quoteHistory || []);
      }
    } catch (error: any) {
      console.error(error?.message);
    }
  };

  useEffect(() => {
    fetchQuoteDetails(Number(id) || 0);
  }, []);

  const handleDeleteModalOpen = (historyId: number) => {
    setDeleteOpenModal(true);
    setQuoteHistoryId(historyId);
  };

  const handleClose = () => {
    setDeleteOpenModal(false);
  };

  const deleteSubmit = async () => {
    try {
      api
        .delete<ApiResponse<boolean>>(`/Quote/history/${quoteHistoryId}`)
        .then(async (response: any) => {
          if (response.isSuccess) {
            toast.success(response.message);
            setDeleteOpenModal(false);
            await fetchQuoteDetails(Number(id));
          }
        })
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const getFormattedDate = (fieldName: string) => {
    return formik.values[fieldName]
      ? moment(formik.values[fieldName]).format(DATE_FORMAT)
      : null
  };

  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    validationSchema: validations,
    onSubmit: async (values) => {
      const data = {
        ...values,
        productId: productIdState,
        processedDate: getFormattedDate("processedDate"),
        quoteDate: getFormattedDate("quoteDate"),
      };

      setDisableSubmit(true);
      try {
        let response: ApiResponse<Boolean>;
        if (id) {
          // Update existing quote
          response = await api.put<ApiResponse<Boolean>>("/Quote", data);
          fetchQuoteDetails(Number(id));
        } else {
          // Create new quote
          response = await api.post<ApiResponse<Boolean>>("/Quote", data);
          navigate("/quotes");
        }

        // Handle successful response here, if needed
        if (response?.isSuccess) {
          toast.success(response?.message);
          setDisableSubmit(false);
        }
      } catch (error) {
        // Handle error, e.g., show an error message to the user
        console.error("Create / Update Quote API Failed", error);
      } finally {
        setDisableSubmit(false); // Re-enable the submit button
      }
    }
  });

  const hasEmptyOrInvalidFields = QUOTE_CALCULATION_FIELDS.some(
    (field) => formik.values[field] === null || formik.values[field] === "" || formik.values[field] === 0
  );

  const shouldFetchCalculationAPI = (fieldName) => {
    const valuesChanged = quoteDetails &&
      QUOTE_CALCULATION_FIELDS.some(field =>
        formik.values[field] !== quoteDetails[field]
      );

    return QUOTE_CALCULATION_FIELDS.includes(fieldName) &&
      !hasEmptyOrInvalidFields &&
      (valuesChanged || !quoteDetails);
  };

  const fetchCalculationAPI = async () => {
    try {
      const {
        fobCost,
        targetFOBCost,
        tariffPercent,
        toolingCost,
        umapPrice
      } = formik.values;

      setDisableSubmit(true);
      const quoteCalculationResponse = await api.post<ApiResponse<QuoteBasicDetail>>(
        "/Quote/calculation",
        {
          productId: productIdState,
          fobCost,
          targetFOBCost,
          tariffPercent,
          toolingCost,
          umapPrice
        }
      );

      if (quoteCalculationResponse?.isSuccess) {
        const { data, message } = quoteCalculationResponse;
        formik.setValues({ ...formik.values, ...data });
        toast.success(message);
        setDisableSubmit(false);
      }
    } catch (error) {
      setDisableSubmit(false);
      console.error("Quote Calculation API call failed", error);
    }
  };

  // Utility function to get approval date stamp
  const getApprovalDateStamp = (isApproved, date, user, fieldName) => {
    if (isApproved === null) return ""; // Return empty string if approval status is null

    const status = isApproved ? "Approved" : "Rejected";
    const formattedDate = date ? new Date(date).toLocaleDateString() : "";

    // Determine the field-specific label
    let fieldLabel = '';
    if (fieldName === QUOTE_APPROVAL_FIELDS.costApproved) {
      fieldLabel = 'Cost';
    } else if (fieldName === QUOTE_APPROVAL_FIELDS.dnApproved) {
      fieldLabel = 'DN and UMAP';
    }

    return `${status} ${fieldLabel} Date Stamp: ${formattedDate} | ${user}`;
  };

  const handleQuoteApproval = async (fieldName, checked) => {
    try {
      // Dynamically update the changed field (either costApproved or dnApproved) while including field in the API call
      const approvalData = {
        id: id,
        [fieldName]: checked,
      };

      setDisableSubmit(true);

      const quoteApprovalResponse = await api.post<ApiResponse<Boolean>>(
        "/Quote/approval",
        approvalData
      );

      if (quoteApprovalResponse?.isSuccess) {
        const { message } = quoteApprovalResponse;
        toast.success(message);
        fetchQuoteDetails(Number(id));
        setDisableSubmit(false);
      }
    } catch (error) {
      setDisableSubmit(false);
      console.error("API call failed", error);
    }
  };

  const isFieldDisabled = (fieldName) => {
    const { costApproved, dnApproved } = formik.values;

    // First, check if the user has the necessary permission
    const hasPermission = sessionService.hasPermission(Permissions.ManageQuote);
    if (!hasPermission) {
        return true; // Disable the field if the user lacks permission
    }

    // Check specific condition for `umapPrice` field
    if ((fieldName === "umapPrice") && costApproved === true && dnApproved !== true) {
      return false; // Allow editing in this case
    }

    // Default behavior for other cases
    return costApproved === true;
  };

  const isSubmitButtonHidden = !(formik.values?.costApproved === true && formik.values?.dnApproved === true);

  const getFieldBackgroundColor = (fieldName) => {
    return isFieldDisabled(fieldName) ? "#EBEBE4" : "#FFFFFF";
  };

  // Helper function to check if a Toggle button field should be read-only
  const isToggleButtonDisabled = (fieldName) => {

    // If quoteDetails is not provided, disable it
    if (!quoteDetails) return true;

    // Check if user has necessary permission
    const hasPermission =
      (fieldName === QUOTE_APPROVAL_FIELDS.costApproved && sessionService.hasPermission(Permissions.ApproveQuoteCost)) ||
      (fieldName === QUOTE_APPROVAL_FIELDS.dnApproved && sessionService.hasPermission(Permissions.ApproveDNAndUMAP));

    if (!hasPermission) return true;

    // Check conditions for read-only fields based on quoteDetails and permissions
    return (
      (fieldName === QUOTE_APPROVAL_FIELDS.costApproved &&
        quoteDetails.costApproved === true) ||
      (fieldName === QUOTE_APPROVAL_FIELDS.dnApproved &&
        (quoteDetails.costApproved !== true || quoteDetails.dnApproved === true))
    );
  };
  
  /**
    * This function is used to render text field
    * @param fieldName - Name of the field
    * @param label - Label of the field
    * @param numeric - Boolean for numeric type field
    */
  const renderTextField = (fieldName, label, numeric = false) => (
    <FormControl className="quote-form-control-row">
      <label htmlFor={`outlined-${label}`}>
        {label}
        <span className="required-field-asterisk-icon">{` *`}</span>
      </label>
      <TextField
        id={`outlined-${label}`}
        value={formik.values[fieldName]}
        label={formik.errors[fieldName] ? formik.errors[fieldName] : ""}
        onChange={formik.handleChange}
        onBlur={(e) => {
          formik.handleBlur(e);
          if (shouldFetchCalculationAPI(fieldName)) {
            fetchCalculationAPI();
          }
        }}
        name={fieldName}
        onKeyDown={(e) => (e.key === "ArrowUp" || e.key === "ArrowDown") && e.preventDefault()}
        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
        className="hide-input-arrow"
        inputProps={{ type: numeric ? 'number' : 'text' }}
        error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
        style={{ backgroundColor: getFieldBackgroundColor(fieldName), flex: 1 }}
        disabled={isFieldDisabled(fieldName)}
      />
    </FormControl>
  );

  /**
   * This function is used to render toogle switch button field
   * @param fieldName - Name of the field
   * @param label - Label of the field
   */
  const renderSwitchButton = (
    fieldName: string = "",
    label: string = "",
  ) => (
    <FormControl className="quote-form-control-row">
      <label
        htmlFor={`outlined-${fieldName}`}
        style={{ textTransform: "capitalize" }}
      >
        {label}
      </label>
      <div>
        <FormControlLabel
          control={
            <ToggleSwitchButton
              checked={formik.values[fieldName] === undefined ? null : formik.values[fieldName]}
              onChange={(e) => formik.setFieldValue(fieldName, e.target.checked)}
              fieldName={fieldName}
              readOnlyField={isFieldDisabled(fieldName)}
            />
          }
          label={formik.values[fieldName] ? "Yes" : "No"}
          style={{ flex: 1 }}
        />
      </div>
    </FormControl>
  );

  const renderThreeStateToggleButton = (
    fieldName: string = "",
    label: string = "",
    isApprovalField: boolean = false
  ) => (
    <FormControl style={{ margin: "5px", width: isApprovalField ? "50%" : "24%" }}>
      <label
        htmlFor={`outlined-${fieldName}`}
        style={{ marginBottom: "10px" }}
      >
        {label}
      </label>
      <div style={{ display: "flex", alignItems: "center" }}>
        <ThreeStateToggle
          value={
            formik.values[fieldName] === true ? "yes" :
              formik.values[fieldName] === false ? "no" :
                "none"
          }
          onChange={(value) => {
            const fieldValue = value === 'yes' ? true : value === 'no' ? false : null;
            if ((fieldName in QUOTE_APPROVAL_FIELDS) && fieldValue !== null) {
              handleOpenApprovalModal(fieldName, fieldValue);
            }
          }}
          fieldName={fieldName}
          readOnlyField={isToggleButtonDisabled(fieldName)}
        />
        {isApprovalField && (
          <div style={{ fontSize: "14px", color: "gray", marginTop: "5px", marginLeft: "30px" }}>
            {/* Only show date stamp if either costApproved or dnApproved is not null */}
            {(formik.values.costApproved !== null || formik.values.dnApproved !== null) && (
              <>
                {fieldName === QUOTE_APPROVAL_FIELDS.costApproved &&
                  getApprovalDateStamp(formik.values.costApproved, formik.values.costApprovedDate, formik.values.costApprovedByDisplayText, fieldName)}
                {fieldName === QUOTE_APPROVAL_FIELDS.dnApproved &&
                  getApprovalDateStamp(formik.values.dnApproved, formik.values.dnApprovedDate, formik.values.dnApprovedByDisplayText, fieldName)}
              </>
            )}
          </div>
        )}
      </div>
    </FormControl>
  );

  /**
   * This function is used to render date picker field
   * @param fieldName - Name of the field
   * @param label - Label of the field
   */
  const renderDatePicker = (fieldName, label) => (
    <FormControl className="quote-form-control-row">
      <label htmlFor={`outlined-${fieldName}`}
        style={{ textTransform: "capitalize" }}
      >
        {label}
        <span className="required-field-asterisk-icon">{` *`}</span>
      </label>
      <TextField
        type="date"
        name={fieldName}
        label={formik.errors[fieldName] ? formik.errors[fieldName] : ""}
        value={moment(formik.values[fieldName]).format("YYYY-MM-DD")}
        onChange={(e) => {
          const value = e.target.value;
          formik.setFieldValue(
            fieldName,
            value ? moment(e.target.value, "YYYY-MM-DD").toDate() : null
          )
        }}
        error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
        onBlur={formik.handleBlur}
        style={{ backgroundColor: getFieldBackgroundColor(fieldName), flex: 1 }}
        disabled={isFieldDisabled(fieldName)}
      />
    </FormControl>
  );

  /**
   * This function is used to render date picker field
   * @param fieldName - Name of the field
   * @param label - Label of the field
   */
  const renderReadOnlyLabel = (fieldName, label) => {

    const symbol = QUOTE_FIELD_SYMBOLS[fieldName];
    const value = formik.values[fieldName] || 0;
  
    const formattedValue = symbol
      ? symbol === "%" // Check for postfix fields
        ? `${value}${symbol}` // Append for postfix
        : `${symbol}${value}` // Prepend for prefix
      : value; // Default case with no symbol
  
    return (
      <FormControl className="quote-form-control-row">
        <label htmlFor={`outlined-${fieldName}`}>{label}</label>
        <span style={{ margin: "10px 0 0 10px" }}>{formattedValue}</span>
      </FormControl>
    );
  };

  const readOnlyFieldsRenderer = {
    cubeMultiplier: (field) => () => renderReadOnlyLabel(field.fieldName, field.label),
    freightCost: (field) => () => renderReadOnlyLabel(field.fieldName, field.label),
    dnPrice: (field) => () => renderReadOnlyLabel(field.fieldName, field.label),
    commissionDollars: (field) => () => renderReadOnlyLabel(field.fieldName, field.label),
    commissionPercent: (field) => () => renderReadOnlyLabel(field.fieldName, field.label),
    dnToUMAPMultiplier: (field) => () => renderReadOnlyLabel(field.fieldName, field.label),
    dutyCost: (field) => () => renderReadOnlyLabel(field.fieldName, field.label),
    grossGMDollars: (field) => () => renderReadOnlyLabel(field.fieldName, field.label),
    grossGMPercent: (field) => () => renderReadOnlyLabel(field.fieldName, field.label),
    landedCost: (field) => () => renderReadOnlyLabel(field.fieldName, field.label),
    netMargin: (field) => () => renderReadOnlyLabel(field.fieldName, field.label),
    netMarginDollars: (field) => () => renderReadOnlyLabel(field.fieldName, field.label),
    percentOfFOB: (field) => () => renderReadOnlyLabel(field.fieldName, field.label),
    royaltyDollars: (field) => () => renderReadOnlyLabel(field.fieldName, field.label),
    royaltyPercent: (field) => () => renderReadOnlyLabel(field.fieldName, field.label),
    tariffDollars: (field) => () => renderReadOnlyLabel(field.fieldName, field.label),
    ipCube: (field) => () => renderReadOnlyLabel(field.fieldName, field.label),
  };

  const editableFieldsRenderer = {
    fobCost: (field) => () => renderTextField(field.fieldName, field.label, true),
    targetFOBCost: (field) => () => renderTextField(field.fieldName, field.label, true),
    tariffPercent: (field) => () => renderTextField(field.fieldName, field.label, true),
    toolingCost: (field) => () => renderTextField(field.fieldName, field.label, true),
    toolingNumber: (field) => () => renderTextField(field.fieldName, field.label),
    toolingOpened: (field) => () => renderSwitchButton(field.fieldName, field.label),
    umapPrice: (field) => () => renderTextField(field.fieldName, field.label, true),
    carNumber: (field) => () => renderTextField(field.fieldName, field.label),
    quoteNumber: (field) => () => renderTextField(field.fieldName, field.label),
    processedDate: (field) => () => renderDatePicker(field.fieldName, field.label),
    quoteDate: (field) => () => renderDatePicker(field.fieldName, field.label),
  };

  /**
   * This function is used to handle accordian group expand / collapse
   * @param group - Accordian group name
   */
  const handleAccordianExpand = (group: string) => {
    const isSectionOpen = accordianExpanded.includes(group);

    // Check if section is expanded (group name present in array)
    if (isSectionOpen) {
      // Remove the group from the expanded array
      const updatedOpenSections = accordianExpanded.filter(sec => sec !== group);
      setAccordianExpanded(updatedOpenSections);
    } else {
      // Add the group to the expanded array
      setAccordianExpanded([...accordianExpanded, group]);
    }
  };

  // Combine editable and read-only field renderers
  const combinedFieldsRenderer = {
    ...editableFieldsRenderer,
    ...readOnlyFieldsRenderer,
  };

  const combinedRendererNames = Object.keys(combinedFieldsRenderer);

  /**
   * This function is used to render accordian based on grouped fields. each groups accordian containg some fields
   * @param group - Accordian group name
   * @param fields - Accordian field name of groups
   */
  const renderFormSection = (
    group: string,
    fields: ProductLayoutFieldDetail[]) => {

    return (
      <Accordion
        key={group}
        expanded={accordianExpanded.includes(group)}
        onChange={() => handleAccordianExpand(group)}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls={`panel-${group}-content`}
          id={`panel-${group}-header`}
          className={true && "active-accordian-expanded"}
        >
          {accordianExpanded.includes(group) ? <RemoveIcon /> : <AddIcon />}
          <Typography style={{ fontWeight: accordianExpanded.includes(group) ? "bold" : "normal" }}>
            {group}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="quote-accordian-fields">
          {fields.sort((a, b) => a.tabOrder - b.tabOrder).map(field => {
            const renderFunction = combinedFieldsRenderer[combinedRendererNames.find((fieldName) => fieldName.toLowerCase() === field.fieldName.toLowerCase())]; // Use the camelCase version of the field name
            return renderFunction ? renderFunction(field)() : null;
          })}
        </AccordionDetails>
      </Accordion>
    );
  };

  const QuoteHistoryGridColumns = [
    {
      field: "actions", headerName: "Actions", width: 80, sortable: false,
      renderCell: (params) => (
        <IconButton onClick={() => handleDeleteModalOpen(params.row.id)}>
          <Delete />
        </IconButton>
      ),
    },
    { field: "id", headerName: "Id", width: 50 },
    { field: "ipCube", headerName: "IP Cube", width: 100 },
    { field: "dnPrice", headerName: "DN", width: 100 },
    { field: "fobCost", headerName: "FOB Cost", width: 100 },
    { field: "cubeMultiplier", headerName: "Cube Multiplier", width: 100 },
    { field: "umapPrice", headerName: "UMAP Price", width: 100 },
    { field: "freightCost", headerName: "Freight Cost", width: 100 },
    { field: "quoteNumber", headerName: "Quote Number", width: 100 },
    { field: "targetFOBCost", headerName: "Target FOB Cost", width: 100 },
    { field: "tariffPercent", headerName: "Tariff Percent", width: 100 },
    { field: "toolingCost", headerName: "Tooling Cost", width: 100 },
    { field: "carNumber", headerName: "CAR Number", width: 100 },
    { field: "toolingNumber", headerName: "Tooling Number", width: 100 },
    { field: "toolingOpened", headerName: "Tooling Opened", width: 100,
      valueGetter: (params) => params.row.toolingOpened ? "Yes" : "No"
     },
    { field: "grossGMDollars", headerName: "Gross GM Dollars", width: 100 },
    { field: "grossGMPercent", headerName: "Gross GM Percent", width: 100 },
    { field: "landedCost", headerName: "Landed Cost", width: 100 },
    { field: "netMargin", headerName: "Net Margin", width: 100 },
    { field: "netMarginDollars", headerName: "Net Margin Dollars", width: 100 },
    {
      field: "quoteDate", headerName: "Quote Date", width: 110,
      valueGetter: (params) => params.row.quoteDate ? moment(params.row.quoteDate).format("YYYY-MM-DD") : ""
    },
    {
      field: "processedDate", headerName: "Processed Date", width: 110,
      valueGetter: (params) => params.row.processedDate ? moment(params.row.processedDate).format("YYYY-MM-DD") : ""
    },
    { field: "historyByDisplayText", headerName: "History By", width: 100 },
    {
      field: "historyDate", headerName: "History Date", width: 110,
      valueGetter: (params) => params.row.historyDate ? moment(params.row.historyDate).format("YYYY-MM-DD") : ""
    },
  ];

  const renderQuoteHistoryGridView = () => {
    return (
      <Box style={{ marginTop: '2%', width: '100%' }}>
        <DataGrid
          rows={quoteHistoryGridData || []}
          columns={QuoteHistoryGridColumns}
          disableColumnMenu
          sortingOrder={['desc', 'asc']}
          pageSizeOptions={[10, 25, 50, 100]}
          pagination
          paginationMode="client"
          rowCount={quoteHistoryGridData.length}
          sortingMode="client"
          getRowHeight={() => 'auto'}
          rowSelection={false}
        />
      </Box>
    );
  };

  const handleOpenApprovalModal = (fieldName, checked) => {
    setFieldToApprove(fieldName);
    setApprovalChecked(checked);
    setShowConfirmationModal(true);
  };

  const handleConfirmApproval = () => {
    // Proceed with API call
    handleQuoteApproval(fieldToApprove, approvalChecked);
    setShowConfirmationModal(false); // Close modal after confirmation
  };

  const isQuoteFormVisible = sessionService.hasPermission(Permissions.ManageQuote) || sessionService.hasPermission(Permissions.ApproveQuoteCost) ||
    sessionService.hasPermission(Permissions.ApproveDNAndUMAP) || sessionService.hasPermission(Permissions.ViewQuotes);

  return (
    <>
      {deleteModalOpen &&
        <DeleteValidationModal
          addModalOpen={deleteModalOpen}
          handleCloseModal={handleClose}
          deleteSubmit={deleteSubmit}
          fieldIdentifier={`quote history #${quoteHistoryId}`}
        />}
      <ConfirmationModal
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        title={"Confirm Approval"}
        message={`Are you sure you want to ${approvalChecked ? "approve" : "reject"
          } this quote?`}
        handleConfirmYes={handleConfirmApproval}
        handleConfirmNo={() => setShowConfirmationModal(false)}
      />
      <Card sx={{ marginBottom: 1 }}>
        <CardContent>
          {isQuoteFormVisible &&
            <>
              <div style={{ display: "flex" }}>
                <Typography variant="h5" fontWeight="bold">
                  {id ? 'Update' : 'Create'} Quote
                   ({shortDesc && `${shortDesc} - `}
                  {stockCode ? stockCode : `Product Id ${productIdState}`})
                </Typography>
                <span style={{ marginLeft: "auto", cursor: "pointer" }}>
                  <CloseIcon onClick={() => navigate("/quotes")} />
                </span>
              </div>
              {id && formik.values?.updatedDate &&
                <Box style={{ display: "flex", flexDirection: "column", alignItems: "end", fontStyle: "italic" }}>
                  <span>DATE OF LAST QUOTE</span>
                  <span>{new Date(formik.values.updatedDate).toLocaleDateString()}</span>
                </Box>}
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                  <Box sx={{ margin: "20px 0" }}>
                    {renderFormSection(PRICING_LAYOUT_CATEGORY.categoryName, PRICING_LAYOUT_CATEGORY.fields)}
                    {renderFormSection(TOOLING_LAYOUT_CATEGORY.categoryName, TOOLING_LAYOUT_CATEGORY.fields)}
                    {id &&
                      <Box marginTop={2} display={"flex"} border={"2px dashed #ccc"} padding={1}>
                        {renderThreeStateToggleButton(QUOTE_APPROVAL_FIELDS.costApproved, 'Cost Approved', true)}
                        {renderThreeStateToggleButton(QUOTE_APPROVAL_FIELDS.dnApproved, 'DN and UMAP Approved', true)}
                      </Box>}
                  </Box>
                  {sessionService.hasPermission(Permissions.ManageQuote) && isSubmitButtonHidden && (
                    <Button
                      type="submit"
                      variant="contained"
                      style={{ margin: "8px 5px", width: "135px" }}
                      disabled={disableSubmit}
                    >
                      Submit
                    </Button>)}
                </form>
              </Box>
            </>}
          {sessionService.hasPermission(Permissions.ViewQuoteHistory) && (
              <div style={{ marginTop: "30px" }}>
                <Typography variant="h5" fontWeight="bold" margin={2} textAlign={"center"}>Quote History</Typography>
                {quoteHistoryGridData?.length > 0 && renderQuoteHistoryGridView()}
                {quoteHistoryGridData?.length === 0 &&
                <Typography variant="body1" margin={3} textAlign={"center"}>No records found</Typography>}
              </div>)}
        </CardContent>
      </Card>
    </>
  )
}

export default CreateUpdateQuote;