import { useEffect, useState } from "react";
import { SecondaryMaterial } from "../../../models/SecondaryMaterial";
import { ApiResponse } from "../../../models/ApiResponse";
import { SettingGrid } from "../../../components/ManageSettingComponents/SettingGrid";
import api from "../../../services/ApiService";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import moment from "moment";
import { Button, Switch } from "@mui/material";
import { toast } from "react-toastify";
import SecondaryMaterialModal from "./SecondaryMaterialModal";
import DeleteValidationModal from "../../../components/ManageSettingComponents/DeleteValidationModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SessionDetails } from "../../../models/SessionDetails";
import { DATE_TIME_FORMAT } from "../../../utils/constants";
import { GRID_ACTION_DELETE_ICON, GRID_ACTION_EDIT_ICON } from "../../../components/GridUtilityComponents";
import { Permissions } from "../../../models/Enum";
import SessionService from "../../../services/SessionService";

export default function SecondaryMaterialSetting() {
  const [secondaryMaterialList, setSecondaryMaterialList] = useState<
    SecondaryMaterial[]
  >([]);
  const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const manageAppSetting = SessionService.getInstance().hasPermission(Permissions.ManageAppSettings);

  const SecondaryGridCollumns: GridColDef[] = [
    { field: "id", headerName: "Id", width: 100 },
    { field: "name", headerName: "Secondary Material", width: 350 },
    {
      field: "isActive",
      headerName: "Is Active",
      width: 200,
      sortable: false,
      renderCell: (params: any) => (
        <>
          <Switch
            checked={params.value}
            onChange={async (e) => {
              await updateSecondaryMaterialActive(params.row, e.target.checked);
            }}
            color={"primary"}
            inputProps={{ "aria-label": "controlled" }}
            disabled = {!manageAppSetting}
          />
        </>
      ),
    },
    // {
    //   field: "updatedDate",
    //   headerName: "Updated Date",
    //   width: 200,
    //   valueFormatter: (params) => moment(params?.value).format(DATE_TIME_FORMAT),
    // },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 200,
      getActions: (params: any) => [
        GRID_ACTION_EDIT_ICON(params, handleEditClickOpen),
        GRID_ACTION_DELETE_ICON(params, handleDeleteModalOpen)
      ],
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const getSecondaryMateiralRepsonse = await api.get<
      ApiResponse<SecondaryMaterial[]>
    >("/PickList/secondary-materials");
    setSecondaryMaterialList(getSecondaryMateiralRepsonse?.data || []);
  };

  const handleEditClickOpen = (gridParams: any) => {
    formik.resetForm();
    setShowAddUpdateModal(true);
    setDisableSubmit(false);
    formik.setValues({ ...gridParams.row });
  };

  const handleAddClickOpen = () => {
    formik.resetForm();
    setShowAddUpdateModal(true);
    setDisableSubmit(false);
  };

  const handleDeleteModalOpen = (gridParams: any) => {
    setDeleteModalOpen(true);
    formik.setValues({ ...gridParams.row });
  };

  const handleCloseModal = () => {
    setShowAddUpdateModal(false);
    setDeleteModalOpen(false);
    formik.resetForm();
  };

  const updateSecondaryMaterialActive = (
    row: SecondaryMaterial,
    isActive: boolean
  ) => {
    row.isActive = isActive;
    try {
      api
        .put<ApiResponse<SecondaryMaterial>>(
          "/Picklist/secondary-material",
          row
        )
        .then(async (response: any) => {
          if (response.isSuccess) {
            toast.success(response.message);
            await fetchData();
          } else {
            throw new Error(response.message);
          }
        })
        .catch(() => {});
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  const deleteSubmit = () => {
    try {
      api
        .delete<ApiResponse<SessionDetails>>(
          `/PickList/secondary-material/${formik.values.id}`
        )
        .then(async (response: any) => {
          if (response.isSuccess) {
            setDeleteModalOpen(false);
            formik.resetForm();
            toast.success(response.message);
            await fetchData();
          } else {
            throw new Error(response.message);
          }
        })
        .catch(() => {});
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      id: 0,
      name: "",
      isActive: true,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required("Secondary Material is required")
        .min(1, "Secondary Material must be at least 1 characters")
        .max(40, "Secondary Material must be at most 40 characters")
        .test(
          "check-name-duplicate",
          "Secondary Material already exists",
          (value) => {
            return !secondaryMaterialList.some(
              (item) =>
                item.name.trim().toLowerCase() === value.trim().toLowerCase() &&
                item.id !== formik.values.id
            );
          }
        ),
    }),
    onSubmit: async (values) => {
      setDisableSubmit(true);
      if (values.id > 0) {
        api
          .put<ApiResponse<boolean>>("/PickList/secondary-material", {
            id: values.id,
            name: values.name.trim(),
            isActive: JSON.parse(`${values.isActive}`),
          })
          .then(async (response: any) => {
            if (response.isSuccess) {
              setShowAddUpdateModal(false);
              formik.resetForm();
              toast.success(response.message);
              await fetchData();
            } else {
              throw new Error(response.message);
            }
          })
          .catch(() => {});
      } else {
        api
          .post<ApiResponse<SessionDetails>>("/PickList/secondary-material", {
            name: values.name,
            isActive: values.isActive,
          })
          .then(async (response: any) => {
            if (response.isSuccess) {
              setShowAddUpdateModal(false);
              formik.resetForm();
              toast.success(response.message);
              await fetchData();
            } else {
              throw new Error(response.message);
            }
          })
          .catch(() => {});
      }
    },
  });

  return (
    <>
      <div>
        {manageAppSetting && <Button variant="contained" onClick={handleAddClickOpen}>
          Add Secondary Material
        </Button>}
        <SecondaryMaterialModal
          isOpen={showAddUpdateModal}
          onClose={handleCloseModal}
          formik={formik}
          isButtonDisabled={disableSubmit}
        />
        <DeleteValidationModal
          addModalOpen={deleteModalOpen}
          handleCloseModal={handleCloseModal}
          deleteSubmit={deleteSubmit}
          fieldIdentifier={"Secondary Material"}
        />
      </div>
      <SettingGrid
        data={secondaryMaterialList}
        column={SecondaryGridCollumns}
        sortByField="id"
        sortByOrder="desc"
        columnVisibility={{
          actions: manageAppSetting,
        }}
      />
    </>
  );
}
