import { GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { DATE_FORMAT } from "../../utils/constants";

// mapping used to find original column name that should be used e.g. for sortBy parameter
export const ProductListReferenceColumnsMapping = {
  brandDisplayText: "brand",
  stageDisplayText: "stage",
  statusDisplayText: "status",
  marketingDesignerDisplayText: "marketingDesigner",
  categoryDisplayText: "category",
  functionDisplayText: "function",
  styleDisplayText: "style",
  skuTypeDisplayText: "skuType",
  departmentDisplayText: "department"
};

export const ProductListGridColumns: GridColDef[] = [
  // { field: "id", headerName: "Id", width: 70, disableColumnMenu: true },
  // {
  //   field: "stockcode",
  //   headerName: "Stock Code",
  //   sortable: true,
  //   disableColumnMenu: true,
  //   width: 180
  // },
  // {
  //   field: "pdStockcode",
  //   headerName: "PD StockCode",
  //   sortable: true,
  //   disableColumnMenu: true,
  //   width: 180,
  // },
  // {
  //   field: "baseCode",
  //   headerName: "Base Code",
  //   sortable: true,
  //   disableColumnMenu: true,
  //   width: 125,
  // },
  // {
  //   field: "pdCode",
  //   headerName: "PD BaseCode",
  //   sortable: true,
  //   disableColumnMenu: true,
  //   width: 120,
  // },
  // {
  //   field: "brandDisplayText",
  //   headerName: "Brand Collection",
  //   sortable: true,
  //   disableColumnMenu: true,
  //   width: 130
  // },  
  // {
  //   field: "shortDesc",
  //   headerName: "Short Description",
  //   sortable: false,
  //   disableColumnMenu: true,
  //   width: 250
  // },
  // {
  //   field: "stageDisplayText",
  //   headerName: "Stage",
  //   sortable: true,
  //   disableColumnMenu: true,
  //   width: 120,
  // },
  // {
  //   field: "statusDisplayText",
  //   headerName: "Status",
  //   sortable: true,
  //   disableColumnMenu: true,
  //   width: 155,
  // },
  {
    field: "pdFamilyName",
    headerName: "PD Family",
    sortable: true,
    disableColumnMenu: true,
    width: 130,
    align:'center',
  },
  {
    field: "seriesName",
    headerName: "Series Name",
    sortable: true,
    disableColumnMenu: true,
    width: 110,
  },
  {
    field: "marketingDesignerDisplayText",
    headerName: "Designer",
    sortable: true,
    disableColumnMenu: true,
    width: 155,
  },
  {
    field: "categoryDisplayText",
    headerName: "Category",
    sortable: true,
    disableColumnMenu: true,
    width: 80,
  },
  {
    field: "functionDisplayText",
    headerName: "Function",
    sortable: true,
    disableColumnMenu: true,
    width:90,
  },
  {
    field: "departmentDisplayText",
    headerName: "Department",
    sortable: true,
    disableColumnMenu: true,
    width: 105,
  },
  {
    field: "styleDisplayText",
    headerName: "Style",
    sortable: true,
    disableColumnMenu: true,
    width: 110,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: "skuTypeDisplayText",
    headerName: "SKU Type",
    sortable: true,
    disableColumnMenu: true,
    width: 220,
  },
  {
    field: "primaryMaterialDisplayText",
    headerName: "Primary Material",
    sortable: true,
    disableColumnMenu: true,
    width: 130,
    align:'center',
  },
  {
    field: "secondaryMaterialDisplayText",
    headerName: "Secondary Material",
    sortable: true,
    disableColumnMenu: true,
    width: 150,
    align: 'center',
  },
  {
    field: "developmentTypeDisplayText",
    headerName: "Development Type",
    sortable: true,
    disableColumnMenu: true,
    width: 150,
    align: 'center',
  },
  {
    field: "slIntroDate",
    headerName: "SL Intro Date",
    sortable: true,
    disableColumnMenu: true,
    width: 120,
    valueGetter: (params) => params?.value ? moment(params?.value).format(DATE_FORMAT) : ''
  }
];
