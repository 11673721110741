// FileDownload.tsx
import { toast } from "react-toastify";
import moment from "moment";
import SessionService from "../services/SessionService";

interface FileDownloadProps {
  requestInput: any;
  inputFileName: string;
  inputFileFormat: string;
  setLoading: (loading: boolean) => void;
}

const FileDownload = async ({ requestInput, inputFileName, inputFileFormat, setLoading }: FileDownloadProps) => {
  const sessionService = SessionService.getInstance();

  const downloadFile = async (response: Response, fileName: string) => {
    const date = moment();
    const formattedDate = date.format('YYYYMMDDHHMMSS');
    const blobData = await response.blob();
    const blobURL = window.URL.createObjectURL(blobData);

    const a = document.createElement('a');
    a.href = blobURL;
    a.style.display = 'none';
    a.download = `${fileName}${formattedDate}`;
    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(blobURL);
  };

  const handleDownload = async () => {
    try {
      const sessionInfo = sessionService.getSessionToken();
      let url = '';

      switch (inputFileFormat) {
        case 'Quote Pricing Tooling':
          url = `${process.env.REACT_APP_API_URL}/quote/export-pricing-tooling`;
          break;
        case 'Quote List':
          url = `${process.env.REACT_APP_API_URL}/quote/export-quote-list-csv`;
          break;
        default:
          throw new Error('Invalid download type');
      }

      setLoading(true);
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + sessionInfo,
        },
        body: JSON.stringify(requestInput),
      });

      const contentType = response.headers.get("content-type");

      if (response.ok) {
        if (contentType && contentType.includes('application/json')) {
          const bodyResp = await response.json();
          toast.error(bodyResp.message);
        } else if (contentType && (contentType.includes('text/csv') || 
                                    contentType.includes('application/zip') || 
                                    contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'))) {
          await downloadFile(response, inputFileName);
          toast.success(`${inputFileFormat} Exported Successfully`);
        }
      } else if (response.status === 404) {
        toast.error('No records found');
      } else {
        toast.error('Error while downloading file');
      }
    } catch (error) {
      console.error('Error while downloading file:', error);
      toast.error('Error while downloading file');
    } finally {
      setLoading(false);
    }
  };

  return {
    handleDownload,
  };
};

export default FileDownload;